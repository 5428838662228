

























import { defineComponent, PropType } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useUiHelpers } from '~/composables';
import { CategoryTree } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'List',
  components: {
    SvgImage,
  },
  props: {
    items: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
    parentName: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();

    return {
      getCatLink,
    };
  },
});
